import React from "react"

import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

const About = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "short" }} />
      <Layout>
        <Container fluid className="info">
          <Container className="info-contain">
            <Row>
              <Col md={12}>
                <h4 className="info-title">About</h4>
              </Col>
              <Col md={3} className="d-none d-lg-block">
                <StaticImage
                  src="../assets/images/portrait-2-bw.jpg"
                  alt="CYAA Portrait"
                  placeholder="blurred"
                  layout="fullWidth"
                />
              </Col>
              <Col md={12} lg={9}>
                <h4 className="info-subhead">Carla Young</h4>
                <p className="info-text">Owner and Appraiser</p>
                <h4 className="info-subhead">Credentials</h4>
                <ul className="info-list">
                  <li>
                    Certificate: Appraisal of Fine and Decorative Arts, New York
                    University
                  </li>
                  <li>Accredited Member: Appraiser's Association of America</li>
                  <li>
                    Qualified: Uniform Standards of Professional Appraisal
                    Practice (USPAP)
                  </li>
                  <li>
                    Bachelor of Fine Arts: State University of New York, Albany,
                    Art History
                  </li>
                  <li>
                    Post Graduate Studies: University of Pittsburgh, Art History
                  </li>
                  <li>
                    Extensive experience in appraisal of fine arts, and in
                    galleries and museums
                  </li>
                  <li>
                    Independent Appraiser of Fine and Decorative Arts.
                    Specialties: American, European and Latin American Fine Art.
                  </li>
                  <li>
                    Owner and Appraiser at Carla Young Fine Arts Appraisals, LLC
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
      </Layout>
    </>
  )
}

export default About
